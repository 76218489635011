import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import loadLibrary from '../Loadable/library';

const Helmet = loadLibrary('react-helmet');

export default function Head(props) {
  const data = useStaticQuery(graphql`
    query SeoQuery {
      wp {
        ...SiteName
        ...SiteUrl
        ...DefaultSeo
      }
    }
  `);
  return <Seo data={data} {...props} />;
}

function Seo({ seo, title, location, data }) {
  const siteName = data.wp.generalSettings.title;
  const siteTitle = `${title} | ${siteName}`;
  const siteUrl = data.wp.options.websiteOptions.general.liveUrl;

  const defaultOgImage =
    data.wp.options.websiteOptions.general.defaultOpengraphImage;
  const defaultTwitterImage =
    data.wp.options.websiteOptions.general.defaultTwitterImage;

  const OgImage =
    seo && seo.opengraphImage
      ? seo.opengraphImage.localFile.publicURL
      : defaultOgImage && defaultOgImage.localFile.publicURL;

  const TwitterImage =
    seo && seo.twitterImage
      ? seo.twitterImage.localFile.publicURL
      : defaultTwitterImage && defaultTwitterImage.localFile.publicURL;

  const today = new Date();
  const date = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const dateTime = `${date} ${time}`;
  return (
    <Helmet title={siteTitle}>
      <html lang="en" />
      <link rel="canonical" href={siteUrl + location} />
      <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "${siteUrl}/#organization",
                "name": "${siteName}",
                "url": "${siteUrl}/",
                "sameAs": []
              },
              {
                "@type": "WebSite",
                "@id": "${siteUrl + location}/#website",
                "url": "${siteUrl}/",
                "name": "${siteName}",
                "publisher": {
                  "@id": "${siteUrl}/#organization"
                }
              },
              {
                "@type": "WebPage",
                "@id": "${siteUrl}/#webpage",
                "url": "${siteUrl}/",
                "inLanguage": "en-GB",
                "name": "${siteTitle}",
                "isPartOf": {
                  "@id": "${siteUrl}/#website"
                },
                "about": {
                  "@id": "${siteUrl}/#organization"
                },
                "datePublished": "${dateTime}",
                "dateModified": "${dateTime}"
              }
            ]
          }
        `}</script>
      <meta property="og:url" content={siteUrl + location} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteName} />
      <meta
        property="og:title"
        content={seo && seo.opengraphTitle ? seo.opengraphTitle : siteTitle}
      />
      <meta
        property="og:description"
        content={
          seo && seo.opengraphDescription
            ? seo.opengraphDescription
            : seo && seo.metaDesc
        }
      />
      <meta name="og:image" content={siteUrl + OgImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={seo && seo.twitterTitle ? seo.twitterTitle : siteTitle}
      />
      <meta
        property="twitter:description"
        content={
          seo && seo.twitterDescription
            ? seo.twitterDescription
            : seo && seo.metaDesc
        }
      />
      <meta property="twitter:image" content={siteUrl + TwitterImage} />
      <meta
        name="description"
        content={seo && seo.metaDesc ? seo.metaDesc : ''}
      />
      <meta name="image" content={siteUrl + OgImage} />
      <meta
        name="generator"
        content="Website and Design by Web Passion - https://www.webpassion.page"
      />
    </Helmet>
  );
}
